

















import axios from 'axios'
import {Component, Prop, Vue} from "vue-property-decorator"

@Component({
  name: "DropdownList",
})
export default class DropdownList extends Vue {
  protected selectedItem: object;
  protected inputValue: string;
  protected itemList: Array<any>;
  protected apiLoaded: boolean;
  protected apiUrl: string;

  constructor() {
    super();
    this.selectedItem = {};
    this.inputValue = '';
    this.itemList = [];
    this.apiLoaded = false;
    this.apiUrl = "https://restcountries.eu/rest/v2/all?fields=name;flag";
    this.getList();
  }

  resetSelection () {
    this.selectedItem = {};
    const dropdowninput: any = this.$refs.dropdowninput;
    this.$nextTick( () => dropdowninput.focus() );
    this.$emit('on-item-reset');
  }

  selectItem (theItem: any) {
    this.selectedItem = theItem;
    this.inputValue = '';
    this.$emit('on-item-selected', theItem);
  }

  itemVisible (item: any) {
    const currentName = item.name.toLowerCase();
    const currentInput = this.inputValue.toLowerCase();
    return currentName.includes(currentInput);
  }

  getList() {
    this.apiUrl = "https://restcountries.eu/rest/v2/all?fields=name;flag";
    axios.get(this.apiUrl).then( response => {
      this.itemList = response.data;
      this.apiLoaded = true;
    })
  }

}

